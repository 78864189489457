<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card p-4" style="border: white">
        <validation-observer ref="formRef" novalidate>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('roleInformation')" />
              </div>

            </div>

            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required|max:256">
                <base-input
                  v-model="model.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('roleName')"
                />
              </validation-provider>
            </div>
            <div class="col-md-6 mt-4 pt-2">
              <b-form-checkbox class="" v-model="model.hasOffice" switch>
                {{ $t('hasOffice') }}
              </b-form-checkbox>
              <b-form-checkbox class="" v-model="model.isAttender" switch>
                {{ $t('visibleInVisitTo') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required|max:256">
                <base-input
                  v-model="model.description"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('description')"
                  :textarea="true"
                />
              </validation-provider>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'rolesSearch' })"
        :text="$t('cancel')"
      />

      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button class="btn btn-primary ml-2" @click="onSubmit(true)">
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import SubHeader from '@/components/SubHeader.vue';
import { manageRole as _manageRole } from '@/services/RoleService';

export default {
  name: 'Create',
  components: {
    BaseInput,
    SubHeader,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async onSubmit(next = false) {
      if (!(await this.$refs.formRef.validate())) return;

      await _manageRole(this.model)
        .then((response) => {
          this.ShowSuccessSaveToast();
          if (!this.model.roleId) {
            this.$router.push({
              name: 'roleEdit',
              params: { id: response.data },
            });
            this.model.roleId = response.data;
          }
          if (next) this.$emit('go-to', 1);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
