<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />
    <ul id="tab-controls" class="nav nav-pills" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(0) }"
          @click.prevent="onStepChange(0)"
        />
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(1) }"
          @click.prevent="onStepChange(1)"
        />
      </li>
    </ul>
    <div id="tab-container">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <create
          class="my-3"
          :model="model"
          @go-to="onStepChange($event)"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <AssignPermissions
          v-if="model.roleId"
          class="my-3"
          :role-id="model.roleId"
          @go-to="onStepChange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Create from './Create';
import AssignPermissions from './AssignPermissions';
import { getById as _getById } from '@/services/RoleService';

export default {
  name: 'RoleMaster',
  components: {
    StepWizard,
    Create,
    AssignPermissions
  },
  data() {
    return {
      stepIndex: 0,
      model: {
        roleId: null,
        name: null,
        description: null,
        hasOffice: true
      }
    }
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('roleMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('assignPermission'),
          icon: 'far fa-lock',
          disabled: !this.model.roleId,
        },
      ];
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
  mounted() {
    if (this.$route.name !== "roleCreate") {
        const roleId = this.$route.params.id;
        if (!roleId) return this.$router.push({ name: 'rolesSearch' });
        _getById(roleId).then(response => {
          this.model = response.data;
        });
    }
    if (this.$route.name === "roleEditPermissions") this.stepIndex = 1;
  }
}
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
