<template>
  <Permissions :value="permissions" @go-to="$emit('go-to', $event)" @on-submit="onSubmit($event)" />
</template>

<script>
import Permissions from '@/components/Permissions';
import { getPermissionsList as _getPermissionsList } from '@/services/RoleService';
import { addPermissions as _addPermissions } from '@/services/RoleService';

export default {
  name: 'AssignPermission',
  components: {
    Permissions
  },
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      permissions: []
    }
  },
  mounted() {
    _getPermissionsList(this.roleId)
    .then(response => {
      this.permissions = response.data;
    })
  },
  methods: {
    onSubmit(finalize) {
      const permissions = this.permissions.filter(x => x.selected).map(x => x.id);
      _addPermissions({roleId: this.roleId, permissions}).then(() => {
        this.ShowSuccessSaveToast();
        if (finalize) this.$router.push({ name: 'rolesSearch' });
      })
    }
  }
};
</script>
