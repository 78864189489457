<template>
  <div class="p-3 listview card border-light">
    <div class="row">
      <div class="col-sm-6 col-md-3">
        <p>
          <b>{{ $t('name') }}</b>
          <br />
          {{ value.value }}
        </p>
      </div>
      <div class="col-sm-6 col-md-2">
         <p>
          <b>{{ $t('hasOffice') }}</b>
          <br />
          {{ $t(value.hasOffice ? 'yes' : 'no')  }}
        </p>
      </div>
      <div class="col-sm-6 col-md-2">
         <p>
          <b>{{ $t('isAttender') }}</b>
          <br />
          {{ $t(value.isAttender ? 'yes' : 'no')  }}
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <p>
          <b>{{ $t('description') }}</b>
          <br />
          {{ value.description }}
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-3">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import SystemRoles from '@/constants/SystemRoles';

export default {
  name: 'RoleSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('edit'),
        },
        {
          label: this.$t('permissions'),
          iconClass: 'far fa-lock',
          iconColor: '#EB6D00',
          action: () => this.$emit('assign-permissions'),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
          visible: () =>
            this.value.key !== SystemRoles.clientuser &&
            this.value.key !== SystemRoles.webuser &&
            this.value.key !== SystemRoles.administrator,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
