<template>
  <section>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="roleNameIsNotCorrect"
      :item="selectedRole"
      label="roleName"
      name="description"
      @on-confirm="onConfirmDelete()"
    />
    <content-header :title="$t('rolesMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterRole')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="roleSearch"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getRoles(1)"
        />
      </div>
      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <separator
        class="my-4"
        :text="$t('listOfRoles')"
      />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => onManage()"
            :text="$t('create')"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(role, index) in roleSearchResult.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="role"
            @assign-permissions="() => addPermissions(role.key)"
            @edit="() => onManage(role.key)"
            @on-delete="onDeleteRole(role)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="roleSearchResult.count > 0"
        id="pgPager"
        class="my-4"
        :data="roleSearchResult"
        :page-size="pageSize"
        @pagination-go-page="getRoles($event)"
        @pagination-rows-per-page="getRoles(pageIndex, $event)"
      />
    </div>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import DeleteModal from '@/components/DeleteModal';
import {
  getRoles as _getRoles,
  deleteRole as _deleteRole
} from '@/services/RoleService';
import sessionState from '@/mixins/sessionState';

export default {
  name: 'RolessList',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    DeleteModal,
  },
  data() {
    return {
      roles: {
        key: [],
      },
      selectedRoles: {
        key: [],
      },
      roleSearch: { value: '' },
      roleSearchResult: {
        key: []
      },
      noResultsFound: false,
      pageSize: 10,
      showDeleteModal: false,
      pageIndex: 1,
      selectedRole: null,
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'roleName',
          label: this.$t('roleName'),
          component: 'BaseInput',
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-6 col-lg-6',
          initialValue: this.roleSearch.value,
          onChanged: this.onKeyChanged,
        },
      ];
    },
  },
  methods: {
    onKeyChanged(value) {
      this.roleSearch.value = value;
    },
    async getRoles(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        value: this.roleSearch.value,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getRoles(payload)
        .then(({ data }) => {
          this.roleSearchResult = data;
        })
    },
    onManage(roleId = null) {
      this.saveSessionData('usersData', Object.assign({}, this.$data));
      this.$router.push(roleId ? {name: 'roleEdit', params: { id: roleId }} : { name: 'roleCreate' });
    },
    addPermissions(roleId) {
      this.saveSessionData('usersData', Object.assign({}, this.$data));

      this.$router.push({ name: 'roleEditPermissions', params: { id: roleId }})
    },
    onDeleteRole(role) {
      this.showDeleteModal = true;
      this.selectedRole = role;
    },
    onConfirmDelete() {
      _deleteRole(this.selectedRole.key)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getRoles();
          this.selectedRole = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmitCreate() {

    },
    onCancelCreate() {
      this.$refs['create-modal-ref'].hide();
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep h6 {
  color: $color-primary;
}
::v-deep textarea {
  resize: none;
}
</style>
